<script lang="ts" setup>
const appUserDataStore = useAppUserData();
const { isUserAdmin, isUserSystemAdmin } = storeToRefs(appUserDataStore);
</script>

<template>
  <NuxtLayout name="scaffold">
    <template #app-bar-title>
      <span class="text-h5"> Settings </span>
    </template>
    <div class="flex flex-col h-full">
      <div>
        <div
          class="flex flex-row bg-surface items-center border rounded mb-4"
          v-if="isUserAdmin"
        >
          <NuxtLink
            to="/settings/"
            class="text-center p-4 grow border-r hover:bg-light-default hover:bg-opacity-20 cursor-pointer"
            :class="{
              'bg-primary-default bg-opacity-20':
                $route.path === '/settings/' || $route.path === '/settings',
            }"
          >
            <div>General</div>
          </NuxtLink>
          <NuxtLink
            to="/settings/app-data"
            class="text-center p-4 grow border-r hover:bg-light-default hover:bg-opacity-20 cursor-pointer"
            :class="{
              'bg-primary-default bg-opacity-20':
                $route.path === '/settings/app-data',
            }"
          >
            <div>App Data</div>
          </NuxtLink>
          <NuxtLink
            to="/settings/checklists"
            class="text-center p-4 grow border-r hover:bg-light-default hover:bg-opacity-20 cursor-pointer"
            :class="{
              'bg-primary-default bg-opacity-20':
                $route.path === '/settings/checklists',
            }"
          >
            <div>Manage Checklists</div>
          </NuxtLink>
          <NuxtLink
            v-if="isUserSystemAdmin"
            to="/settings/reports"
            class="text-center p-4 grow hover:bg-light-default hover:bg-opacity-20 cursor-pointer"
            :class="{
              'bg-primary-default bg-opacity-20':
                $route.path === '/settings/reports',
            }"
          >
            <div>Reports</div>
          </NuxtLink>
        </div>
      </div>
      <div class="grow min-h-0 overflow-auto">
        <slot />
      </div>
    </div>
  </NuxtLayout>
</template>

<style scoped></style>
